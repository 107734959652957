import isCallHRApplicable from "screens/common/utils/callHr";
import React, { useEffect, useRef, useState } from "react";
import { Dimensions, Platform, StyleSheet, View } from "react-native";
import SActionCard from "styled42/components/SActionCard";
import SButton from "styled42/components/SButton";
import SCallButton from "styled42/components/SCallButton";
import SLoader from "styled42/components/SLoader";
import SText from "styled42/components/SText";
import { Colors } from "styled42/config";
import { pushClevertapEvent } from "utils/clevertapEvents";
import getData from "utils/getData";
import { sendEventToGA } from "utils/googleAnalyticsEvents";
import { mLog } from "utils/logger";
import { navigate } from "utils/routing/NavRouter";
import VernacManager from "utils/vernacular";
import { buttonType } from "screens/common/constants";
import { SetStorage } from "screens/common/LocationContext/storage";
import {
  handleApply,
  navigateUserAfterApplying,
  saveContextAndNavigateToRegister
} from "screens/common/JobCard/CTA/utils";
import { deleteJobFromCache } from "screens/common/JobCard/CTA/utils/common";
import LocationPopUp from "../LocationPopUp";
import { getLocationsCityCheck } from "./utils/locationUtils";

const CTA = (props) => {
  const [isCalling, setIsCalling] = useState(false);
  const [isApplying, setIsApplying] = useState(false);
  const [showPopUp, setShowPopUp] = useState(false);

  const mounted = useRef(false);

  const {
    authToken,
    callHrVariant,
    client,
    clevertapProps,
    date,
    isWalkIn,
    jobCardVariant,
    jobId,
    jobGet,
    jobSlug,
    keywords,
    locations,
    month,
    pricingPlanType,
    referrer,
    queryVariables,
    showSnackbar,
    setLocation,
    user
  } = props;

  let callHRApplicable = false;

  if (jobGet) {
    const jobData = jobGet();
    callHRApplicable = isCallHRApplicable(jobData);
  }

  const DesktopWidth =
    (Platform.OS === "web" && Dimensions.get("window").width < 800) ||
    Platform.OS === "android";

  const profileLocation = getData(
    user,
    "stakeholder.address.locality.city.name"
  );

  const finalLocation = locations?.length > 0 ? locations[0].place.city : "";

  useEffect(() => {
    mounted.current = true;

    return () => {
      mounted.current = false;
    };
  }, []);

  const handleLocation = () => {
    pushClevertapEvent("General Clicks", {
      Page_Modal: "Location warning Pop-up",
      Type: "Edit Location"
    });

    navigate("editUserLocation", {
      jobId,
      candId: user?.stakeholder?.id,
      address: JSON.stringify(user?.stakeholder?.address) || "",
      fromScreen: "jobList",
      headerName: "Select new work location",
      pricingPlanType,
      jobSlug,
      isWalkIn,
      queryVariables: JSON.stringify(queryVariables),
      showSnackbar
    });

    setShowPopUp(false);
  };

  const invokeCallHR = async () => {
    sendEventToGA("job_card_click", "Call_HR_Click", `JC_${jobCardVariant}`);

    if (!authToken) {
      mLog(`============= save context and navigate to login`);
      saveContextAndNavigateToRegister({
        jobId,
        jobSlug,
        pricingPlanType,
        option: buttonType.CallHR
      });
      return;
    }

    const mobileVerified = user && user.mobile_verified;
    if (mobileVerified) {
      handleApplyClick(buttonType.CallHR);
    }
  };

  const invokeApplyAction = async (e) => {
    if (Platform.OS === "web") {
      e.preventDefault();
      e.stopPropagation();
    }

    sendEventToGA("job_card_click", "Apply_Click", `JC_${jobCardVariant}`);

    pushClevertapEvent("Job Clicks", {
      ...clevertapProps,
      Type: "Apply",
      "Test case": `JC_${jobCardVariant}`
    });

    const distance = await getLocationsCityCheck(client, locations);

    if (!authToken) {
      mLog(`============= save context and navigate to login`);
      saveContextAndNavigateToRegister({
        jobId,
        jobSlug,
        pricingPlanType,
        option: isWalkIn ? buttonType.FixInterview : buttonType.Apply
      });
      SetStorage("locationMissmatchCheck", JSON.stringify(distance));
      return;
    }

    if (distance && distance > 100) {
      pushClevertapEvent("Page Load", {
        Page_Modal: "Location warning Pop-up"
      });
      setShowPopUp(true);
    } else {
      handleApplyClick();
    }
  };

  const handleApplyClick = async (bType) => {
    handleApply({
      props,
      onStart: onApplyStart,
      onError: onApplyError,
      onSuccess: onApplySuccess,
      bType
    });
  };

  const toggleLoader = (loaderType, toggle) => {
    if (mounted.current) {
      if (loaderType === buttonType.CallHR) {
        setIsCalling(toggle);
      } else {
        setIsApplying(toggle);
      }
    }
  };

  const onApplyStart = (bType) => {
    toggleLoader(bType, true);
  };

  const onApplyError = (bType) => {
    toggleLoader(bType, false);
    if (showSnackbar) {
      showSnackbar("Error! Please Try Again.");
    }
  };

  const onApplySuccess = (applicationData, bType) => {
    // Delete job from cache
    if (queryVariables) {
      deleteJobFromCache(jobId, queryVariables, client);
    }
    toggleLoader(bType, false);
    // Show success message
    // if (showSnackbar && applicationData) {
    //   showSnackbar("Job card has been moved to Applications tab.", "primary");
    // }
    navigateUserAfterApplying({
      application: applicationData,
      props,
      bType
    });
    toggleLoader("", false);
  };

  return (
    <View style={styles.container}>
      {isCalling ? (
        <View style={styles.loaderContainer}>
          <SLoader color={Colors.Blue[8]} size={28} />
        </View>
      ) : (
        callHRApplicable &&
        DesktopWidth && (
          <SCallButton
            buttonType="secondary"
            clevertapProps={clevertapProps}
            callHrVariant={callHrVariant}
            onClick={invokeCallHR}
            size="sm"
            text={VernacManager.translateString("generic_call_hr")}
            containerStyle={styles.smallButton}
            jobCardVariant="A" // to track job card variant in call hr click
          />
        )
      )}
      {isApplying ? (
        <View style={styles.loaderContainer}>
          <SLoader color={Colors.Blue[8]} size={28} />
        </View>
      ) : (
        <>
          {isWalkIn ? (
            <View style={styles.applyContainer}>
              <SButton
                text={VernacManager.translateString("generic_apply")}
                textProps={{ size: 12 }}
                containerStyle={styles.button}
                onClick={invokeApplyAction}
              />
              <View style={styles.walkInContainer}>
                <SText size={12} color="#003035" weight="500">
                  Walk-in{" "}
                </SText>
                <SText size={12} color="secondary">
                  Interview on{" "}
                </SText>
                <SText size={12} color="#003035" weight="500">
                  {date} {month}
                </SText>
              </View>
            </View>
          ) : (
            <SButton
              text={VernacManager.translateString("generic_apply")}
              textProps={{ size: 12 }}
              containerStyle={styles.button}
              onClick={invokeApplyAction}
            />
          )}
        </>
      )}
      {showPopUp && (
        <SActionCard
          visible={showPopUp}
          scrollViewStyle={styles.actionCardStyle}
        >
          <LocationPopUp
            handleLocation={handleLocation}
            location={finalLocation}
            profileLocation={profileLocation}
            keywords={keywords}
            setLocation={setLocation}
            referrer={referrer}
            closeModal={setShowPopUp}
          />
        </SActionCard>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  applyContainer: {
    display: "flex",
    flexDirection: "row-reverse",
    alignItems: "center",
    height: 32
  },
  container: {
    display: "flex",
    alignItems: "flex-end",
    marginTop: 17,
    height: 72
  },
  walkInContainer: {
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
    paddingVertical: 7,
    paddingHorizontal: 13,
    borderWidth: 1,
    borderColor: Colors.Grey[4],
    borderStyle: "dashed",
    borderRightWidth: 0,
    borderBottomLeftRadius: 4,
    borderTopLeftRadius: 4
  },
  loaderContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    minWidth: 84,
    height: 32
  },
  button: {
    borderRadius: 8,
    minWidth: 84,
    height: 32
  },
  smallButton: {
    flex: 1,
    borderRadius: 8,
    width: 84,
    height: 32,
    marginBottom: 8
  },
  actionCardStyle: {
    height: 260,
    backgroundColor: "white"
  }
});

export default CTA;

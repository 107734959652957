import { GetLocation } from "screens/common/LocationContext";
import { GET_CITIES } from "screens/Profile/queries";
import { graphQLQueryHelper } from "utils/graphQL";

export const getLocationsCityCheck = async (client, locations) => {
  const getLocation = await GetLocation();
  let distance = 0;

  if (locations?.[0]?.place?.city && getLocation?.city?.name) {
    const jobCardCity = await GetCityLatLong(locations[0].place.city, client);
    const userLocationCity = await GetCityLatLong(
      getLocation.city.name,
      client
    );

    if (
      jobCardCity?.searchCity?.length > 0 &&
      userLocationCity?.searchCity?.length > 0
    ) {
      distance = calculateDistance(
        jobCardCity.searchCity[0],
        userLocationCity.searchCity[0]
      );
    }
  }
  return distance;
};

const GetCityLatLong = async (city, client) => {
  let finalJobCity = city;

  if (finalJobCity) {
    // Replace hyphen with space
    finalJobCity = finalJobCity.replace(/-/g, " ");
    if (finalJobCity.toLowerCase() === "bengaluru") {
      finalJobCity = "Bangalore";
    } else if (finalJobCity.toLowerCase() === "visakhapatnam") {
      finalJobCity = "Vizag";
    } else if (finalJobCity.toLowerCase() === "gurugram") {
      finalJobCity = "Gurgaon";
    } else if (finalJobCity.toLowerCase() === "new delhi") {
      finalJobCity = "Delhi";
    }
  }

  const { data } = await graphQLQueryHelper(client)({
    query: GET_CITIES,
    variables: {
      query: finalJobCity
    }
  });

  return data;
};

const calculateDistance = (position1, position2) => {
  // Haversine formula
  const lat1 = position1.city_latitude;
  const lon1 = position1.city_longitude;
  const lat2 = position2.city_latitude;
  const lon2 = position2.city_longitude;
  const R = 6371; // Radius of the earth in km
  const dLat = deg2rad(lat2 - lat1); // deg2rad below
  const dLon = deg2rad(lon2 - lon1);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) *
      Math.cos(deg2rad(lat2)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const d = R * c; // Distance in km
  return d;
};

const deg2rad = (deg) => {
  return deg * (Math.PI / 180);
};

import React, { useMemo } from "react";
import { View, Platform } from "react-native";
import SClickableArea from "styled42/components/SClickableArea";
import SText from "styled42/components/SText";
import { Colors } from "styled42/config";
import { Rupee } from "styled42/icons";
import VernacManager from "utils/vernacular";
import S3Image from "screens/common/S3Image";
import CTA from "screens/common/JobCard/CTA";
import {
  getFormattedExperienceText,
  getFormattedPlaceText,
  getSalarySlabText,
  getWalkInJobDetails,
  shareOnWhatsapp
} from "screens/common/JobCard/utils";
import {
  getApplicationMetricesData,
  getTagImageProps
} from "screens/common/utils/common";
import styles from "./index.style";

const JobCard = (props) => {
  const {
    applicationMetrics,
    authToken,
    callHrVariant,
    client,
    containerStyle,
    clevertapProps,
    experience,
    functionalArea,
    isActive,
    isFeatured,
    isResumeRequired,
    isWalkInJob,
    jobCardVariant,
    jobGet,
    jobId,
    jobSlug,
    keywords,
    location,
    locations,
    offer,
    organizationName,
    places,
    pricingPlanType,
    query = {},
    queryVariables,
    recentSlot,
    referrer,
    setLocation,
    setModalClose,
    setModalOpen,
    showSnackbar,
    sources,
    tags,
    title,
    user
  } = props;

  const cardStyles = [styles.cardTopSection];
  if (isFeatured) {
    cardStyles.push(styles.featuredCardStyles);
  }

  const locationDetails = useMemo(
    () => getFormattedPlaceText(places),
    [places]
  );
  const experienceText = useMemo(
    () => getFormattedExperienceText(experience),
    [experience]
  );
  const salaryText = useMemo(() => getSalarySlabText(offer), [offer]);
  // const peopleJoinedDetails = useMemo(
  //   () => getApplicationMetricesData(applicationMetrics),
  //   [applicationMetrics]
  // );
  const walkInDetails = useMemo(
    () => getWalkInJobDetails(recentSlot),
    [recentSlot]
  );

  const tagImageProps = getTagImageProps(pricingPlanType, isActive);

  return (
    <View style={containerStyle}>
      <View style={cardStyles}>
        <View style={[styles.cardDetailBlock, styles.cardDetailBlock_justify]}>
          <SText
            containerStyle={styles.cardDetailBlock_title}
            size={16}
            lineHeight={24}
            color="primary"
            weight="500"
            transform="capitalize"
          >
            {title}
          </SText>
          {isFeatured ? (
            <View style={styles.featuredContainer}>
              <SText style={styles.featuredTag}>
                {VernacManager.translateString("generic_featured")}
              </SText>
              <SText style={styles.featuredShadow} />
            </View>
          ) : (
            tagImageProps && <S3Image {...tagImageProps} />
          )}
        </View>
        <SText
          size={12}
          color={Colors.Purple[1]}
          weight="600"
          transform="capitalize"
        >
          {organizationName}
        </SText>
        <View style={[styles.cardDetailBlock, styles.cardDetailBlock__margin]}>
          <SText size={12} color="primary" transform="capitalize">
            {locationDetails?.displayLocation}&nbsp;
          </SText>
          {locationDetails.distanceText ? (
            <SText size={12} color="secondary">
              {locationDetails?.distanceText}&nbsp;
            </SText>
          ) : null}
          {location && locationDetails.distanceText ? (
            <SText size={12} color="primary" transform="capitalize">
              {location}&nbsp;
            </SText>
          ) : null}
          {locationDetails.otherLocationsCount !== 0 ? (
            <SText size={12} color="primary" weight="bold">
              {`+${VernacManager.translateStringWithValues("jd_others", [
                locationDetails.otherLocationsCount
              ])}`}
            </SText>
          ) : null}
        </View>
        <View style={styles.cardDetailBlock}>
          <SText size={12} color="secondary">
            {experienceText}&nbsp;
          </SText>
          {/* {tags.indexOf("freshers_can_apply") > -1 ? (
            <SText size={12} color="secondary">
              | Freshers can apply
            </SText>
          ) : null} */}
        </View>
        <View style={[styles.cardDetailBlock, styles.cardDetailBlock_salary]}>
          <View style={{ marginRight: Platform.OS === "web" ? 8 : 4 }}>
            <Rupee />
          </View>
          <SText size={12} color="#13101d" weight="500" transform="capitalize">
            {salaryText}&nbsp;
          </SText>
          <SText size={12} color="#676574">
            {VernacManager.translateString("jd_monthly")}
          </SText>
          {/* <SClickableArea
            onClick={(e) => {
              if (Platform.OS === "web") {
                e.preventDefault();
                e.stopPropagation();
              }
              shareOnWhatsapp({ locations, jobSlug, jobId, title });
            }}
            containerStyle={styles.shareSection}
          >
            <SText size={12} color="secondary">
              Share
            </SText>
            <S3Image
              path="OlxIntegration"
              name="whatsapp-icon"
              style={styles.shareSection_icon}
            />
          </SClickableArea> */}
        </View>
        <View style={!isWalkInJob ? styles.ctaButton : null}>
          <CTA
            callHrVariant={callHrVariant}
            queryVariables={queryVariables}
            jobId={jobId}
            jobSlug={jobSlug}
            date={walkInDetails.date}
            month={walkInDetails.month}
            functionalArea={functionalArea}
            pricingPlanType={pricingPlanType}
            isResumeRequired={isResumeRequired}
            isWalkIn={isWalkInJob}
            offer={offer}
            locations={locations}
            query={query}
            user={user}
            sources={sources}
            authToken={authToken}
            client={client}
            clevertapProps={clevertapProps}
            showSnackbar={showSnackbar}
            setModalOpen={setModalOpen}
            setModalClose={setModalClose}
            keywords={keywords}
            referrer={referrer}
            setLocation={setLocation}
            jobCardVariant={jobCardVariant}
            jobGet={jobGet}
            {...props}
          />
        </View>
      </View>
      {/* {peopleJoinedDetails.countText !== "" ? (
        <View style={styles.cardBottomSection}>
          <S3Image
            path="OlxIntegration"
            name={peopleJoinedDetails.iconName}
            style={{
              marginRight: 8,
              ...peopleJoinedDetails.iconStyle
            }}
          />
          <View style={styles.cardDetailBlock}>
            <SText size={12} weight="bold">
              {peopleJoinedDetails.countText}&nbsp;
            </SText>
            <SText size={12}>{peopleJoinedDetails.message}</SText>
          </View>
        </View>
      ) : null} */}
    </View>
  );
};

export default React.memo(JobCard);

import { monthsCamelCase } from "utils/dates";
import { monthsToYears } from "utils/dateFormatting";
import { safeLinkShare } from "screens/common/utils/Share/utils";
import { pushClevertapEvent } from "utils/clevertapEvents";
import config from "config";
import getData from "utils/getData";
import { getClosestLocation } from "screens/common/utils/common";
import VernacManager from "utils/vernacular";

export const formatSalary = (num) =>
  `${num}`.replace(
    /(\d)(?=(?:\d{3})+(?:\.|$))|(\.\d\d?)\d*$/g,
    (m, s1, s2) => s2 || `${s1},`
  );

export const getSalarySlabText = (offer) => {
  const salaryFormat = offer?.salary_format;
  let minSalary = offer?.min_offered_salary;
  let maxSalary = offer?.max_offered_salary;
  let salaryText = "";

  if (salaryFormat === "ANNUAL") {
    minSalary = Math.round(minSalary / 12);
    maxSalary = Math.round(maxSalary / 12);
  }

  if (!minSalary) {
    salaryText = `Upto ${formatSalary(maxSalary)}`;
  } else if (!maxSalary) {
    salaryText = `${formatSalary(minSalary)}`;
  } else if (minSalary === maxSalary) {
    salaryText = `${formatSalary(maxSalary)}`;
  } else {
    salaryText = `${formatSalary(minSalary)} - ${formatSalary(maxSalary)}`;
  }
  return salaryText;
};

export const getFormattedExperienceText = (experience) => {
  let experienceText = "";
  const minExperience = experience && experience.min_experience;
  const maxExperience = experience && experience.max_experience;
  if (minExperience || maxExperience) {
    experienceText = `${monthsToYears(minExperience)}${maxExperience
        ? ` - ${monthsToYears(maxExperience)} ${VernacManager.translateString(
          "jobcard_exp_required"
        )}`
        : ` ${VernacManager.translateString("jobcard_exp_required")}`
      }`;
  } else {
    experienceText = `${VernacManager.translateString(
      "jobcard_no_exp_required"
    )}`;
  }
  return experienceText;
};

const getLocationDisplayDetails = (places, distance) => {
  if (places && places.length) {
    const shortFormattedAddress = places[0].short_formatted_address;
    return {
      displayLocation: shortFormattedAddress,
      otherLocationsCount: places.length > 1 ? places.length - 1 : 0,
      distanceText: distance > 1 ? `${distance} kms from` : null
    };
  }
  return {
    displayLocation: "",
    otherLocationsCount: 0,
    distanceText: ""
  };
};

export const getFormattedPlaceText = (
  places,
  locationFromUrl,
  locationObjFromCookie
) => {
  // When there is no location in the URL
  if (!locationFromUrl || locationFromUrl === "India") {
    return getLocationDisplayDetails(places);
  }

  // When the short_formatted_address in places array contains the location from the URL
  for (let i = 0; i < places.length; i += 1) {
    const shortFormattedAddress = places[i]?.short_formatted_address;
    if (
      shortFormattedAddress
        .toLowerCase()
        .includes(locationFromUrl.toLowerCase())
    ) {
      return getLocationDisplayDetails(places);
    }
  }

  // When the short_formatted_address in places array does not contain the location from the URL,
  // Show the closest place by calculating the distance from the user's latitude and longitude
  if (
    Object.entries(locationObjFromCookie).length > 0 &&
    locationObjFromCookie.latitude &&
    locationObjFromCookie.longitude
  ) {
    const { locations, distance } = getClosestLocation(
      locationObjFromCookie,
      places
    );
    const roundedDistance = Math.round(distance);
    return getLocationDisplayDetails(places, roundedDistance);
  }

  return getLocationDisplayDetails(places);
};

export const getWalkInJobDetails = (recentSlot) => {
  const recentSlotDate = new Date(recentSlot);
  return {
    date: recentSlotDate.getDate(),
    month: monthsCamelCase[recentSlotDate.getMonth()]
  };
};

export const shareOnWhatsapp = ({ locations, jobSlug, jobId, title }) => {
  const city = getData(locations, "0.place.city");
  const shareLink = `${config.WEB_BASE_URL}/job/${jobSlug}/${jobId}/?utm_source=waahjobs&utm_medium=user_share&utm_campaign=share_listing`;
  const shareMessage = `Hey! I found a wonderful job for you on Rocket.\n *Job Title* : ${title}\n *Location*: ${city}\n *See Details*`;

  pushClevertapEvent("Job clicks", {
    "Page modal": "Job Listing",
    Type: "share_listing"
  });

  safeLinkShare({
    platform: "whatsapp",
    message: `${shareMessage}`,
    link: `${shareLink}`
  });
};

export const getFormattedPostedOnText = (postedOn) => {
  if (!postedOn) return null;

  const milliSecondsPerDay = 1000 * 60 * 60 * 24;
  const now = new Date();

  const diff = now - new Date(postedOn);
  const diffTime = Math.abs(diff);

  if (diffTime <= milliSecondsPerDay) {
    if (diffTime < 1000 * 60 * 60 * 8) {
      let hr = diffTime / (1000 * 60 * 60);
      hr = Math.floor(hr);
      return VernacManager.translateStringWithValues("jobcard_hours_ago", [
        `${hr}`
      ]);
    }
    return VernacManager.translateString("jobcard_few_hours_ago");
  }

  const diffDays = Math.ceil(diffTime / milliSecondsPerDay);

  return VernacManager.translateStringWithValues("jobcard_days_ago", [
    `${diffDays}`
  ]);
};

import React from "react";
import { View, StyleSheet } from "react-native";
import SText from "styled42/components/SText";
import SButton from "styled42/components/SButton";
import { pushClevertapEvent } from "utils/clevertapEvents";
import { navigate } from "utils/routing/NavRouter";
import { slugify } from "utils/parseUrl";

const LocationPopUp = ({
  handleLocation,
  location,
  profileLocation,
  keywords,
  setLocation,
  referrer,
  closeModal
}) => {
  const finalLocation = location.charAt(0).toUpperCase() + location.slice(1);

  const replaceLocation = () => {
    let slug = "jobs";
    if (keywords && keywords.length > 0) {
      slug = `${keywords[0]}-jobs-in-${profileLocation}`;
    } else {
      slug = `${slug}-in-${profileLocation}`;
    }

    pushClevertapEvent("General Clicks", {
      Page_Modal: "Location warning Pop-up",
      Type: "Show Jobs"
    });

    closeModal(false);

    if (referrer === "exploreJobList") {
      setLocation({ name: profileLocation });
    } else {
      navigate("jobList", { slug: slugify(slug) });
    }
  };

  return (
    <View style={styles.popUpContainer}>
      <SText
        size={24}
        weight="600"
        color="#150e1f"
      >{`This job is in ${finalLocation}`}</SText>
      <SText
        size={12}
        color="#276ef1"
      >{`Your current work location is ${profileLocation}`}</SText>
      <View style={styles.popUpButtonContainer}>
        <SButton
          type="primary"
          text={`Show jobs in ${profileLocation}`}
          textProps={{ containerStyle: { fontSize: 14 } }}
          containerStyle={styles.buttonContainerStyle}
          onClick={replaceLocation}
        />
        <SButton
          type="tertiary"
          text={`Change work location ${finalLocation}`}
          textProps={{ containerStyle: { fontSize: 14 } }}
          containerStyle={[styles.buttonContainerStyle, { marginTop: 20 }]}
          onClick={handleLocation}
        />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  popUpContainer: {
    paddingHorizontal: 16,
    paddingTop: 24,
    paddingBottom: 28
  },
  popUpDescription: {
    marginTop: 4
  },
  popUpButtonContainer: {
    paddingTop: 36
  },
  buttonContainerStyle: {
    // height: 48,
    // width: "47%"
  }
});

export default LocationPopUp;

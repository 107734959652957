import { Platform, StyleSheet } from "react-native";
import { Colors } from "styled42/config";

const styles = StyleSheet.create({
  cardContainer: {
    // borderColor: Colors.Grey[3],
    // borderWidth: 1,
    // borderRadius: 4
  },
  cardTopSection: {
    backgroundColor: "#ffffff",
    ...Platform.select({
      web: {
        borderRadius: "inherit"
      },
      android: {
        borderRadius: 10
      }
    }),
    paddingHorizontal: 16,
    paddingVertical: 12,
    position: "relative"
  },
  cardDetailBlock: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center"
  },
  cardDetailBlock_justify: {
    justifyContent: "space-between"
  },
  cardDetailBlock_title: {
    marginRight: 8,
    maxWidth: "75%",
    overflow: "hidden",
    ...Platform.select({
      web: {
        textOverflow: "ellipsis",
        whiteSpace: "nowrap"
      }
    })
  },
  cardDetailBlock_salary: {
    marginTop: 8
  },
  cardDetailBlock__margin: {
    marginTop: 6,
    marginBottom: 2
  },
  ctaButton: {
    position: "absolute",
    bottom: 12,
    right: 16
  },
  // shareSection: {
  //   ...Platform.select({
  //     web: {
  //       display: "inline-flex",
  //       cursor: "pointer"
  //     },
  //     android: {
  //       display: "flex"
  //     }
  //   }),
  //   flexDirection: "row",
  //   alignItems: "center",
  //   backgroundColor: "white",
  //   borderRadius: 2,
  //   borderWidth: 1,
  //   borderColor: Colors.Grey[3],
  //   paddingVertical: 2,
  //   paddingRight: 2,
  //   paddingLeft: 6
  // },
  // shareSection_icon: {
  //   height: 20,
  //   width: 20,
  //   marginLeft: 4
  // },
  cardBottomSection: {
    backgroundColor: Colors.LightBlue[1],
    paddingVertical: 12,
    paddingHorizontal: 16,
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginTop: -2
  },
  featuredCardStyles: {
    backgroundColor: "#fffdf9",
    ...Platform.select({
      web: {
        borderWidth: "2px",
        boxShadow: "0 1px 6px 0 rgba(0, 40, 83, 0.16);"
      },
      android: {
        borderWidth: 2
      }
    }),
    borderColor: "#f09928"
  },
  featuredContainer: {
    position: "absolute",
    ...Platform.select({
      web: {
        right: "-16px"
      },
      android: {
        right: -16
      }
    })
  },
  featuredTag: {
    backgroundColor: "#f09928",
    color: "#fff",

    ...Platform.select({
      web: {
        paddingTop: "4px",
        paddingBottom: "4px",
        paddingLeft: "15px",
        paddingRight: "14px",
        fontSize: "10px",
        lineHeight: "12px",
        borderRadius: "1px"
      },
      android: {
        paddingTop: 4,
        paddingBottom: 4,
        paddingLeft: 15,
        paddingRight: 14,
        fontSize: 10,
        lineHeight: 12,
        borderRadius: 1
      }
    }),

    fontWeight: "700",
    textTransform: "uppercase"
  },
  featuredShadow: {
    position: "absolute",
    right: 0,
    width: 0,
    height: 0,
    ...Platform.select({
      web: {
        bottom: "-8px",
        borderTopWidth: "8px",
        borderLeftWidth: "7px",
        borderTopStyle: "solid",
        borderLeftStyle: "solid"
      },
      android: {
        bottom: -8,
        borderTopWidth: 8,
        borderLeftWidth: 7
      }
    }),
    borderTopColor: "#a24701",
    borderLeftColor: "transparent"
  }
});

export default styles;

import generateShareLink from "screens/common/utils/Share/utils/generateShareLink";
import { Linking, Platform } from "react-native";
import { mLog } from "utils/logger";

const safeLinkShare = ({
  platform = "whatsapp",
  link,
  message,
  title,
  redirectURI,
  useFallback = true
}) => {
  const { url, fallbackURL } = generateShareLink({
    platform,
    link: encodeURIComponent(link),
    message: encodeURIComponent(message),
    title,
    redirectURI
  });
  if (Platform.OS === "web") {
    Linking.openURL(fallbackURL);
  } else {
    Linking
      .canOpenURL(url)
      .then(supported => {
        if (!supported) {
          mLog(`Cannot handle url: ${url}`);
          if (useFallback) {
            Linking.openURL(fallbackURL);
          }
        } else {
          Linking.openURL(url);
        }
      })
      .catch(err => {
        mLog(`Error ocurred while opening url: ${err}`);
      });
  }
}

export {
  safeLinkShare,
  generateShareLink
};


import config from "config";

const generateShareLink = ({
  platform,
  link,
  message,
  title,
  redirectURI
}) => {
  const result = {
    url: "",
    fallbackURL: ""
  };

  const text = message || title;
  const payload = `${text}: ${link}`

  switch (platform) {
    case "whatsapp":
      result.url = `whatsapp://send?text=${payload}`;
      result.fallbackURL = `https://api.whatsapp.com/send?text=${payload}`;
      break;
    case "messenger":
      result.url = `fb-messenger://share?app_id=${config.FB_APP_ID}&link=${link}`;
      // Facebook's Send Dialog API doesn't work for mobile devices
      // result.fallbackURL = `https://www.facebook.com/dialog/send?display=popup&app_id=${config.FB_APP_ID}&link=${link}&redirect_uri=${redirectURI}`;
      result.fallbackURL = `fb-messenger://share?app_id=${config.FB_APP_ID}&link=${link}`;
      break;
    case "telegram":
      result.url = `tg://msg?text=${text}&url=${link}`;
      result.fallbackURL = `https://telegram.me/share/msg?&text=${text}&url=${link}`;
      break;
    default:
      result.url = `whatsapp://send?text=${payload}`;
      result.fallbackURL = `https://api.whatsapp.com/send?text=${payload}`;
  }
  return result;
};

export default generateShareLink;
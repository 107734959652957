function isCallHRTime() {
  const date = new Date();
  if (date.getDay() !== 0 && date.getHours() >= 9 && date.getHours() <= 20) {
    return true;
  }
  return false;
}

function isHRContactAvailable(jobDetails) {
  // don't check call_poc here since it might be not be available when logged out

  if (
    jobDetails &&
    jobDetails.pricing_plan_type &&
    (jobDetails.pricing_plan_type === "JOB_POSTING" ||
      jobDetails.pricing_plan_type === "FREE") &&
    jobDetails.share_contact_to_public
  ) {
    return true;
  }

  if (
    jobDetails &&
    jobDetails.pricing_plan_type &&
    (jobDetails.pricing_plan_type === "PREPAID" ||
      jobDetails.pricing_plan_type === "POSTPAID" ||
      jobDetails.pricing_plan_type === "TEMP_STAFFING") &&
    ((jobDetails.associated_cse &&
      jobDetails.associated_cse.user &&
      jobDetails.associated_cse.user.mobile) ||
      (jobDetails.primary_job_scheduler &&
        jobDetails.primary_job_scheduler.user &&
        jobDetails.primary_job_scheduler.user.mobile))
  ) {
    return true;
  }

  return false;
}

/*  we need to pass pricing_plan_type ,share_contact_to_public, associated_cse, associated_cse.user, associated_cse.user.mobile */

export default function isCallHRApplicable(jobDetails) {
  if (
    jobDetails?.pricing_plan_type &&
    isCallHRTime() &&
    isHRContactAvailable(jobDetails)
  ) {
    return true;
  }
  return false;
}

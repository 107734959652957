import VernacManager from "utils/vernacular";

export const monthsToYears = (months, showMonthText = false) => {
  const numYears = Math.floor(months / 12);
  const numMonths = months % 12;
  let monthText = "";
  if (showMonthText) {
    monthText = ""
  } else {
    monthText = numMonths > 1
      ? VernacManager.translateString("jobcard_months")
      : VernacManager.translateString("jobcard_month");
  }
  const yearText =
    numYears > 1
      ? VernacManager.translateString("jobcard_years")
      : VernacManager.translateString("jobcard_year");
  const result = [];
  if (numYears) {
    result.push(`${numYears} ${yearText}`);
  }
  if (!numYears || numMonths) {
    result.push(`${numMonths} ${monthText}`);
  }
  return result.join(" ");
};

// Returns date object which supported in all platforms
export const safeDate = (date) => {
  if (typeof date === "string" || typeof date === "object") {
    return new Date(date);
  }
  return new Date();
};

export const twelveHoursTimeFormatting = (
  str,
  amText = "am",
  pmText = "pm"
) => {
  if (str) {
    let time = str
      .toString()
      .match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [str];
    if (time.length > 1) {
      time = time.slice(1);
      time[5] = +time[0] < 12 ? amText : pmText;
      time[0] = +time[0] % 12 || 12;
    }
    time.splice(3, 1);
    return time.join("");
  }
  return "";
};
